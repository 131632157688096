exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tobo-comics-tsx": () => import("./../../../src/pages/tobo-comics.tsx" /* webpackChunkName: "component---src-pages-tobo-comics-tsx" */),
  "component---src-pages-tobo-components-credits-tsx": () => import("./../../../src/pages/tobo-components/credits.tsx" /* webpackChunkName: "component---src-pages-tobo-components-credits-tsx" */),
  "component---src-pages-tobo-components-description-cards-tsx": () => import("./../../../src/pages/tobo-components/description-cards.tsx" /* webpackChunkName: "component---src-pages-tobo-components-description-cards-tsx" */),
  "component---src-pages-tobo-components-loading-tobo-tsx": () => import("./../../../src/pages/tobo-components/loading-tobo.tsx" /* webpackChunkName: "component---src-pages-tobo-components-loading-tobo-tsx" */),
  "component---src-pages-tobo-components-lock-chapters-tsx": () => import("./../../../src/pages/tobo-components/lock-chapters.tsx" /* webpackChunkName: "component---src-pages-tobo-components-lock-chapters-tsx" */),
  "component---src-pages-tobo-components-scroll-buttons-tsx": () => import("./../../../src/pages/tobo-components/scroll-buttons.tsx" /* webpackChunkName: "component---src-pages-tobo-components-scroll-buttons-tsx" */),
  "component---src-pages-tobo-components-survey-compenent-tsx": () => import("./../../../src/pages/tobo-components/survey-compenent.tsx" /* webpackChunkName: "component---src-pages-tobo-components-survey-compenent-tsx" */)
}

